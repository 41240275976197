import React from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image } from "react-bootstrap";
import { Articles } from "../../../data/Articles";

function BasicArticle({ slug }) {
  const { t, i18n } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{Articles[slug][i18n.language]["title"]}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          {Articles[slug][i18n.language]["title"]}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div>{Articles[slug][i18n.language]["createdAt"]}</div>
      <hr />
      <div className="mb-3">{Articles[slug][i18n.language]["text"]}</div>
      {Articles[slug]["imageSources"].map((imgSrc, idx) => (
        <div key={idx}>
          <Image src={imgSrc} fluid />
        </div>
      ))}
    </Container>
  );
}

export default BasicArticle;
