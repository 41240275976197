import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb } from "react-bootstrap";

import { Articles } from "../../data/Articles";
import Card from "./Card";

function News() {
  const { t, i18n } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("news.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("news.header")}</Breadcrumb.Item>
      </Breadcrumb>

      {Object.keys(Articles)
        .reverse()
        .map((slug) => {
          return (
            Articles[slug][i18n.language] && (
              <Card
                key={slug}
                title={Articles[slug][i18n.language]["title"]}
                slug={slug}
                createdAt={Articles[slug]["createdAt"]}
                summary={Articles[slug][i18n.language]["summary"]}
              />
            )
          );
        })}
    </Container>
  );
}

export default News;
