import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Row, Col } from "react-bootstrap";
import TeamCard from "./TeamCard";

function Team() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("team.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("team.header")}</Breadcrumb.Item>
      </Breadcrumb>

      <Row className="justify-content-center">
        <Col md="4">
          <TeamCard
            initials="mm"
            name="Marek Masalski"
            jobTitle={t("team.mm.jobTitle")}
            degree={t("team.mm.degree")}
            description={t("team.mm.description")}
          />
        </Col>
        <Col md="4">
          <TeamCard
            initials="wk"
            name="Wiesław Kosowicz"
            jobTitle={t("team.wk.jobTitle")}
            degree={t("team.wk.degree")}
            description={t("team.wk.description")}
          />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="4">
          <TeamCard
            initials="jm"
            name="Jerzy Mularczyk"
            jobTitle={t("team.jm.jobTitle")}
            degree={t("team.jm.degree")}
            description={t("team.jm.description")}
          />
        </Col>
        <Col md="4">
          <TeamCard
            initials="ps"
            name="Paweł Stalmach"
            jobTitle={t("team.ps.jobTitle")}
            degree={t("team.ps.degree")}
            description={t("team.ps.description")}
          />
        </Col>
        <Col md="4">
          <TeamCard
            initials="mk"
            name="Mirek Kozdra"
            jobTitle={t("team.mk.jobTitle")}
            degree={t("team.mk.degree")}
            description={t("team.mk.description")}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Team;
