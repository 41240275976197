import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image, Table } from "react-bootstrap";
import { Articles } from "../../../data/Articles";

const CenteredImageWithCaption = styled.div`
  text-align: center;
  margin-top: 3vh;
  margin-bottom: 3vh;
  & > div {
    color: #868e96;
  }
`;

function GeniuszeIMarzyciele() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">
        {Articles["geniusze-i-marzyciele-zygmunt-pulawski-polskie-skrzydla"]["pl"]["title"]}
      </h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
         {Articles["geniusze-i-marzyciele-zygmunt-pulawski-polskie-skrzydla"]["pl"]["title"]}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>{Articles["geniusze-i-marzyciele-zygmunt-pulawski-polskie-skrzydla"]["createdAt"]}</div>
      <hr />
      <div className="mb-3">
        <div>
          {Articles["geniusze-i-marzyciele-zygmunt-pulawski-polskie-skrzydla"]["pl"]["text"]}
        </div>
        <CenteredImageWithCaption>
          <a href="https://vod.tvp.pl/programy,88/geniusze-i-marzyciele-odcinki,318794/odcinek-9,S01E09,1572086">
            <Image
              src={
                require(`../../../assets/news/geniusze-i-marzyciele-zygmunt-pulawski-polskie-skrzydla-article-1.jpg`)
                  .default
              }
              fluid
            />
          </a>
        </CenteredImageWithCaption>
      </div>
    </Container>
  );
}

export default GeniuszeIMarzyciele;
