import ProgressBar from "react-bootstrap/ProgressBar";

function PlContent() {
  return (
    <div>
      <h3 className="mt-5">(1/6) Projekt wstępny</h3>
      <ProgressBar now={100} label={`${100}%`} />
      <div className="initial-project">
        <ol type="I">
          <li>
            GEOMETRIE
            <ol>
              <li>Geometria skrzydła</li>
              <li>Geometria ustrzenia poziomego</li>
              <li>Geometria ustrzenia pionowego</li>
              <li>Geometria kadłuba</li>
              <li>Geometria podwozia głównego</li>
              <li>Geometria podwozia ogonowego</li>
              <li>Geometria zespołu napędowego</li>
              <li>Geometria kinematyki mechanizmów sterowniczych</li>
            </ol>
          </li>
          <li>
            KOMPLETACJA WYPOSAŻENIA
            <ol>
              <li>Przyrządy pokładowe</li>
              <li>Osprzęt elektro i radio</li>
              <li>Osprzęt bezpieczeństwa</li>
              <li>Agregaty instalacji pokładowych</li>
            </ol>
          </li>
          <li>
            ANALIZA CIĘŻAROWA SAMOLOTU
            <ol>
              <li>Położenie SCA</li>
              <li>Rozkład masy samolotu i charakterystyki masowe samolotu</li>
              <li>Zakres wędrówki SC</li>
            </ol>
          </li>
          <li>
            PROJEKT AERODYNAMICZNY SAMOLOTU
            <ol>
              <li>Biegunowa skrzydła</li>
              <li>Biegunowa samolotu</li>
              <li>Osiągi obliczeniowe samolotu</li>
              <li>Analiza stateczności</li>
            </ol>
          </li>
          <li>
            OBWIEDNIA OBCIĄŻEŃ
            <ol>
              <li>Krzywa obciążeń</li>
              <li>Obciążenia od podmuchów</li>
            </ol>
          </li>
        </ol>
      </div>
      <h3 className="mt-5">(2/6) Projekt techniczny</h3>
      <ProgressBar now={80} label={`${80}%`} />
      <div className="technical-project">
        <ol type="I">
          <li>
            DOKUMENTACJA KONSTRUKCYJNA
            <ol>
              <li>Kadłub</li>
              <li>Podwozie główne</li>
              <li>Pdowozie ogonowe</li>
              <li>Usterzenie poziome</li>
              <li>Usterzenie pionowe</li>
              <li>Zespół napędowy + inst. rozruchowa i sterowanie mocą</li>
              <li>Skrzydła</li>
              <li>Sterowanie płatowcem</li>
              <li>Fotel + pasy</li>
              <li>Instalacja paliwowa</li>
              <li>Instalacja olejowa</li>
              <li>Instalacja tlenowa</li>
              <li>
                Osprzęt pokładowy i instalacja ciśnieniowa przyrządów
                pokładowych
              </li>
              <li>Instalacja gaśnicza</li>
              <li>Śmigło</li>
              <li>Uzbrojenie</li>
            </ol>
          </li>
          <li>
            DOKUMENTACJA OBLICZENIOWA
            <ol>
              <li>
                Dokumentacja obliczeniowa obejmuje rachunek sprawdzający
                wytrzymałość konstrukcji poddanej obciążeniom zewnętrznym.. Jej
                pracochłonność stanowi około 1/3 pracochłonności stworzenia
                dokumentacji konstrukcji samolotu.
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <h3 className="mt-5">(3/6) Wyprodukowanie repliki</h3>
      <ProgressBar now={10} label={`${10}%`} />
      <div className="rejoinder-production">
        <ol>
          <li>
            Zgromadzenie niezbędnej ilości materiałów w odpowiednim asortymencie
            dla wyprodukowania części składowych struktury samolotu i
            niezbędnego oprzyrządowania produkcyjnego
          </li>
          <li>Wytworzenie potrzebnej ilości blachy duralowej drobnofalistej</li>
          <li>
            Wytworzenie oprzyrządowania produkcyjnego:
            <ul>
              <li>foremniki detali</li>
              <li>
                przyrządy montażowe: kadłuba (część skorupowa i obie
                kratownice), podwozia, skrzydeł, usterzenia poziomego,
                usterzenia pionowego, łoża silnikowego, kolektora wydechowego,
                pierścienia Townend'a
              </li>
            </ul>
          </li>
          <li>Wytworzenie części i zespołów struktury samolotu</li>
          <li>Montaż samolotu, wyposażenie go i malowanie</li>
          <li>
            Zgłoszenie zakończenia budowy i gotowości samolotu do prób w locie
          </li>
        </ol>
      </div>
      <h3 className="mt-5">(4/6) Obciążenia zewnętrzne samolotu</h3>
      <ProgressBar now={0} label={`${0}%`} />
      <div className="external-aircraft-load">
        <ol>
          <li>Obciążenia skrzydła</li>
          <li>Obciążenia ustezenia poziomego</li>
          <li>Obciążenia ustezenia pionoweg</li>
          <li>Obciążenia lote</li>
          <li>Obciążenia podwozia łównego</li>
          <li>Obciążenia oponowego</li>
          <li>Obciążenia zespołu napędowego</li>
          <li>Obciążenia kadłuba</li>
        </ol>
      </div>
      <h3 className="mt-5">(5/6) Przygotowanie do lotu</h3>
      <ProgressBar now={0} label={`${0}%`} />
      <div className="flight-preparation">
        <ol type="I">
          <li>
            OPRACOWANIE PROGRAMÓW (uzgodnionych z ULC - KCSP)
            <ol>
              <li>
                Próby statyczne
                <ul>
                  <li>
                    Program prób funkcjonalnych głównych mechanizmów
                    sterowniczych
                  </li>
                  <li>Program prób sztywności</li>
                  <li>
                    Program prób wytrzymałości statycznej wraz z uzasadnieniami
                  </li>
                </ul>
              </li>
              <li>
                Próby w locie
                <ul>
                  <li>
                    Program prób naziemnych funkcjonowania instalacji samolotu
                  </li>
                  <li>Program oblotu (uzgodniony z ULC - KCSP)</li>
                  <li>Program prób w locie(uzgodniony z ULC - KCSP)</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            PRZEPROWADZENIE PRÓB STATYCZNYCH
            <ol>
              <li>Wytworzenie wyposażenia do prób statycznych</li>
              <li>WYkonanie prób</li>
              <li>Sporządzenie sprawozdań z prób</li>
            </ol>
          </li>
          <li>
            ANALIZA WŁASNOŚCI AEROSPRĘŻYSTYCH SAMOLOTU
            <ol>
              Na podstawie sprawozdań
              <li>Wytworzenie wyposażenia do prób statycznych</li>
              <li>WYkonanie prób</li>
              <li>Sporządzenie sprawozdań z prób</li>
            </ol>
          </li>
          <li>
            PRÓBY NAZIEMNE
            <ol>
              <li>Wykonanie prób</li>
              <li>Wydanie sprawdozdania</li>
            </ol>
          </li>
          <li>
            OPRACOWANIE DOKUMENTACJI TOWARZYSZĄCEJ
            <ol>
              <li>Tymczasowa instrukcja użytkowania w locie</li>
              <li>
                Tymczasowa instrukcja zapewnienia ciągłej zdatności do lotu
              </li>
            </ol>
          </li>
          <li>
            PRÓBY W LOCIE
            <ol>
              <li>Wykonanie próbnaziemnych</li>
              <li>Wydanie sprawozdania z prób naziemnych</li>
              <li>Oblot samolotu</li>
              <li>Próby w locie (25÷50 godzin lotu)</li>
            </ol>
          </li>
        </ol>
      </div>
      <h3 className="mt-5">(6/6) Po zakończeniu prób w locie</h3>
      <ProgressBar now={0} label={`${0}%`} />
      <div className="after-successful-flight">
        <ol>
          <li>Wydać sprawozdanie z prób w locie</li>
          <li>Wydać INSTRUKCJĘ UŻYTKOWANIA W LOCIE</li>
          <li>Wydać INSTRUKCJĘ ZAPEWNIENIA CIĄGLĘJ ZDATNOŚCI DO LOTU</li>
        </ol>
      </div>
      <h3 className="mt-5">UWAGI KOŃCOWE</h3>
      <p>
        Samolot będzie budowany w kategorii "SPECJALNY" w podkategorii S5 na
        podstawie: ZASADY DOPUSZCZANIA DO LOTU STATKÓW POWIETRZNYCH KATEGORII
        "SPECJALNY" I OGÓLNA PROCEDURA WYDAWANIA POZWOLENIA NA WYKONANIE LOTÓW W
        KATEGORII "SPECJALNY". Wprowadzonych Decyzją Nr 4 GILC z dnia 22 III
        2001 r.
      </p>
    </div>
  );
}

export default PlContent;
