import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb } from "react-bootstrap";
import PlContent from "./PlContent";
import EnContent from "./EnContent";

import "./Progress.css";

function Progress() {
  const { t, i18n } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("progress.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("progress.header")}</Breadcrumb.Item>
      </Breadcrumb>

      {i18n.language === "pl" && <PlContent />}
      {i18n.language === "en" && <EnContent />}
    </Container>
  );
}

export default Progress;
