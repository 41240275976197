import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Container from "react-bootstrap/Container";

import "swiper/swiper-bundle.css";

const WelcomePanel = styled.div`
  height: 20vh;
  min-height: 150px;
  background: #d10000;
  color: white;
  text-align: center;
  margin-bottom: 4vh;
  justify-content: center;
`;

const FoundationName = styled.div`
  padding-top: 6.5vh;
  font-size: 45px;
  text-transform: uppercase;
  @media (max-width: 991px) {
    padding-top: 3vh;
  }
  @media (max-width: 655px) {
    font-size: 35px;
  }
  @media (max-width: 418px) {
    font-size: 33px;
  }
  @media (max-width: 394px) {
    font-size: 30px;
  }
`;

function Home() {
  const { t } = useTranslation("common");

  return (
    <div>
      <WelcomePanel>
        <FoundationName>{t("foundation.name")}</FoundationName>
      </WelcomePanel>

      <Container className="justify-text">
        <p>{t("home.p1")}</p>
        <p>{t("home.p2")}</p>
        <p>{t("home.p3")}</p>
        <p>{t("home.p4")}</p>

        <blockquote className="blockquote">
          <footer className="blockquote-footer">{t("foundation.board")}</footer>
        </blockquote>
        <div
          className="embed-responsive embed-responsive-16by9 mb-4"
          width="600"
        >
          <video controls>
            <source
              src={require(`../assets/P11C.mp4`).default}
              type="video/mp4"
            />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
        <div className="text-center logo">
          <img
            src={require("../assets/samoloty_historyczne_logo_300.jpg").default}
            alt="Logo"
          ></img>
        </div>
      </Container>
    </div>
  );
}

export default Home;
