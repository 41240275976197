import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image } from "react-bootstrap";

function Cooperation() {
  const { t } = useTranslation("common");
  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("cooperation.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("cooperation.header")}</Breadcrumb.Item>
      </Breadcrumb>

      <p>{t("cooperation.p1")}</p>

      <div className="partners">
        <hr />
        <div className="partner">
          <div className="text-center">
            <a href="http://www.muzeumlotnictwa.pl">
              <Image
                src={
                  require(`../assets/partners/muzeum_lotnictwa_polskiego_logo.jpg`)
                    .default
                }
                fluid
              />
            </a>
          </div>
        </div>
        <hr />
      </div>
    </Container>
  );
}

export default Cooperation;
