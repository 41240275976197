import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Table } from "react-bootstrap";

function History() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("history.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("history.header")}</Breadcrumb.Item>
      </Breadcrumb>

      <p>{t("history.p1")}</p>
      <p>{t("history.p2")}</p>
      <p>{t("history.p3")}</p>
      <p>{t("history.p4")}</p>
      <p>{t("history.p5")}</p>

      <div className="mt-4">
        <h3>{t("history.technical.data")}</h3>
        <Table>
          <tbody>
            <tr>
              <th>{t("history.technical.wing_span")}</th>
              <td>10,72 m</td>
            </tr>
            <tr>
              <th>{t("history.technical.length")}</th>
              <td>7,55 m</td>
            </tr>
            <tr>
              <th>{t("history.technical.gross_weight")}</th>
              <td>1650 kg</td>
            </tr>
            <tr>
              <th>{t("history.technical.max_speed")}</th>
              <td>375 km/h</td>
            </tr>
            <tr>
              <th>{t("history.technical.service_ceiling")}</th>
              <td>8000 m</td>
            </tr>
            <tr>
              <th>{t("history.technical.range")}</th>
              <td>550 km</td>
            </tr>
            <tr>
              <th>{t("history.technical.armament.header")}</th>
              <td>
                <ul>
                  <li>{t("history.technical.armament.li1")}</li>
                  <li>{t("history.technical.armament.li2")}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{t("history.technical.engine.header")}</th>
              <td>{t("history.technical.engine.data")}</td>
            </tr>
          </tbody>
        </Table>
      </div>

      <blockquote className="blockquote">
        <footer className="blockquote-footer">
          {t("history.source")}{" "}
          <cite>
            <a href="http://www.muzeumlotnictwa.pl/">{t("history.museum")}</a>
          </cite>
        </footer>
      </blockquote>
    </Container>
  );
}

export default History;
