import GLightbox from "glightbox";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Tabs, Tab } from "react-bootstrap";

const IMAGE_NUMBERS = {
  documentation: [
    "000",
    "001",
    "003",
    "004",
    "005",
    "006",
    "007",
    "008",
    "009",
    "012",
    "013",
    "015",
    "016",
    "017",
    "018",
    "020",
    "021",
    "022",
    "023",
    "025",
  ],
  inventory: [
    "000",
    "001",
    "002",
    "003",
    "004",
    "005",
    "007",
    "008",
    "009",
    "010",
    "011",
    "012",
    "013",
    "014",
    "015",
    "063",
    "064",
    "065",
    "066",
    "067",
    "068",
    "069",
    "070",
    "071",
    "072",
    "074",
    "076",
    "078",
    "080",
    "081",
    "083",
    "084",
    "085",
    "087",
    "089",
  ],
  history: [
    "003",
    "004",
    "007",
    "008",
    "009",
    "011",
    "012",
    "015",
    "016",
    "018",
    "020",
    "022",
    "023",
    "024",
    "026",
    "027",
    "028",
    "029",
    "030",
    "032",
    "033",
    "035",
    "036",
    "037",
    "038",
    "040",
    "041",
    "043",
    "044",
    "045",
    "046",
    "047",
    "048",
    "049",
    "050",
    "053",
    "054",
    "056",
    "057",
    "058",
    "060",
    "061",
    "086",
    "088",
  ],
  other: [
    "004",
    "005",
    "006",
    "007",
    "008",
    "092",
    "093",
    "094",
    "095",
    "096",
    "097",
  ],
};

function Gallery() {
  const { t } = useTranslation("common");

  const onImageClick = (e, galleryFolderName, idx) => {
    e.preventDefault();

    const lightbox = GLightbox({
      elements: IMAGE_NUMBERS[galleryFolderName].map((number) => {
        return {
          href: require(`../assets/gallery/${galleryFolderName}/${number}.jpg`)
            .default,
          type: "image",
        };
      }),
      startAt: idx,
      touchNavigation: true,
      autoplayVideos: false,
    });

    lightbox.open();
  };

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("gallery.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("gallery.header")}</Breadcrumb.Item>
      </Breadcrumb>

      <Tabs defaultActiveKey="technical_documentation" className="mb-3">
        <Tab
          eventKey="technical_documentation"
          title={t("gallery.tabs.technical_documentation")}
        >
          {IMAGE_NUMBERS.documentation.map((number, idx) => (
            <a
              href={
                require(`../assets/gallery/documentation/${number}.jpg`).default
              }
              onClick={(e) => onImageClick(e, "documentation", idx)}
              key={`documentation-${number}`}
            >
              <img
                src={
                  require(`../assets/gallery/documentation/thumb-${number}.jpg`)
                    .default
                }
                alt=""
              />
            </a>
          ))}
        </Tab>
        <Tab
          eventKey="technical_inventory"
          title={t("gallery.tabs.technical_inventory")}
        >
          {IMAGE_NUMBERS.inventory.map((number, idx) => (
            <a
              href={
                require(`../assets/gallery/inventory/${number}.jpg`).default
              }
              onClick={(e) => onImageClick(e, "inventory", idx)}
              key={`inventory-${number}`}
            >
              <img
                src={
                  require(`../assets/gallery/inventory/thumb-${number}.jpg`)
                    .default
                }
                alt=""
              />
            </a>
          ))}
        </Tab>
        <Tab eventKey="history" title={t("gallery.tabs.history")}>
          {IMAGE_NUMBERS.history.map((number, idx) => (
            <a
              href={require(`../assets/gallery/history/${number}.jpg`).default}
              onClick={(e) => onImageClick(e, "history", idx)}
              key={`history-${number}`}
            >
              <img
                src={
                  require(`../assets/gallery/history/thumb-${number}.jpg`)
                    .default
                }
                alt=""
              />
            </a>
          ))}
        </Tab>
        <Tab eventKey="other" title={t("gallery.tabs.other")}>
          {IMAGE_NUMBERS.other.map((number, idx) => (
            <a
              href={require(`../assets/gallery/other/${number}.jpg`).default}
              onClick={(e) => onImageClick(e, "other", idx)}
              key={`other-${number}`}
            >
              <img
                src={
                  require(`../assets/gallery/other/thumb-${number}.jpg`).default
                }
                alt=""
              />
            </a>
          ))}
        </Tab>
      </Tabs>
    </Container>
  );
}

export default Gallery;
