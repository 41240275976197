import { Button, Row, Col, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const PostCard = styled.div`
  margin: 5vh;
`;

function Card({ title, slug, createdAt, summary }) {
  const { t } = useTranslation("common");
  const newsUrl = `/news/${slug}`;

  return (
    <PostCard>
      <Row>
        <Col lg="6">
          <LinkContainer to={newsUrl}>
            <Image
              src={require(`../../assets/news/${slug}-card.jpeg`).default}
              fluid
              rounded
            />
          </LinkContainer>
        </Col>
        <Col lg="6">
          <h2>
            <Link to={newsUrl}>{title}</Link>
          </h2>
          <small>{createdAt}</small>
          <p>{summary}</p>
          <LinkContainer to={newsUrl}>
            <Button variant="danger">{t("news.card.read_more")} →</Button>
          </LinkContainer>
        </Col>
      </Row>
    </PostCard>
  );
}

export default Card;
