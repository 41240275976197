import React from "react";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Image } from "react-bootstrap";

function EnBadinHistoriaNiezwykla() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">
        Niezwykła historia pozyskania kontrolera lotu BADIN
      </h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <LinkContainer to="/news">
          <Breadcrumb.Item>{t("menu.news")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>
          Niezwykła historia pozyskania kontrolera lotu BADIN
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>2021-12-28</div>
      <hr />
      <div className="mb-3">
        Last year, we established contact with the enthusiast of historical
        aircraft, Mr. Stefan Wilski.  Mr. Stefan is a Belgian of Polish origin,
        he works in a private aviation museum in Brussels - BAPA -
        <a href="https://bapa.aero/"> https://bapa.aero/</a>, dealing with the
        renovation and exhibition of historic aircraft. 
      </div>
      <div className="mb-3">
        During long discussions about our plans to build the PZL P11c, we asked
        for help in obtaining a flight controller for our aircraft. After quite
        a long search, we managed to find a suitable instrument in a private
        collection. The owner of the collection turned out to be Mr. Didier
        Campion, who lives in Belgium.
      </div>
      <div className="mb-3">
        A very interesting story begins here. Mr. Campion's cousin, Second
        Lieutenant Robert Vander Auwera (original name) , fought in 1920 as a
        volunteer in an air squadron of the Polish Army. Unfortunately, apart
        from a few photographs, little more was known about the fate of the
        cousin.
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-1.jpg`)
            .default
        }
        fluid
        style={{ maxHeight: "500px" }}
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-2.jpg`)
            .default
        }
        fluid
        style={{ maxHeight: "500px" }}
      />
      <div className="mb-3" style={{ color: "#868e96" }}>
        Second Lieutenant Robert Vander Auwera - photos from the collection of
        Mr. Campion
      </div>
      <div className="mb-3">
        We were very curious about this story, so we started searching our
        archives and we did find a lot of information previously unknown to Mr.
        Campion's family. Here are some of them:
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-3.jpg`)
            .default
        }
        fluid
      />
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-4.jpg`)
            .default
        }
        fluid
      />
      <div className="mb-3 mt-3">
        The excerpt from the memoirs of Wacław Makowski, publishing house ZP
        ISBN 978-83-61529-87-3 - „Cywil w wojsku – wspomnienia z życia i
        wojen cz.I i II„ ("Civilian in the army - memories of life and wars,
        parts I and II"), where Wacław Makowski describes a joint reconnaissance
        flight with Second Lieutenant Robert Vander Auwera, which did not end
        well for the young Belgian, read here:
      </div>
      <div className="mt-5 mb-5">****</div>
      <div className="mb-3">
        The war was not over yet, assignments and flights were becoming more and
        more difficult for observers. Determining where the front is, how the
        attack is proceeding, etc. It was necessary to forget that behind the
        front line it was possible to chop whatever was in sight. In Białystok,
        the squadron was assigned back to the rtm. Buckiewicz Group. He did not
        come for the inspection, and I did not seek contact with him either. We
        got assignments, sent reports, flying sorties, and that's how it went.
        In general, the squadron's life provided little attractions, food was
        constantly scarce, and when it became available it was just vile.
        Hunting did not help much, cartridges were hard to find, and playing
        bridge was not fun, either.
      </div>
      <div className="mb-3">
        Thus, it become quite a sensation when a handsome young Belgian officer,
        2nd Lt. Robert Vanderauver was assigned and arrived to our squadron.
      </div>
      <div className="mb-3">
        [Robert Vanderauver (aka Vanderauvera) Belgian volunteer. He arrived in
        Warsaw at the end of August 1920 and enlisted in the Polish army. On
        September 17 he was assigned to the 1st Reconnaissance Squadron. On
        September 24, he was wounded during his first combat flight. After
        hospital treatment, he did not manage to return to the squadron and
        later was awarded the Cross of Valor. He remained in the Polish army
        until 1924, he graduated from the pilot school and was promoted to the
        rank of lieutenant. After leaving the army, he married a Polish woman
        and conducted business mainly between Poland and Belgium. He spent most
        of his time in Poland, which he left only during World War II, when he
        moved to France.]
      </div>
      <div className="mb-3">
        No one spoke French in the squadron, the language knowledge from school
        was enough to raise a toast, persuade one more drink and take care of
        other important life matters, but that was all. Who is he, what does he
        know, what does he want from us, what does the Group HQ want from him
        and us? We didn't know, and we put these things off until tomorrow.
        Vanderauver came with the dog (wolfhound) and I took care of him,
        because I could communicate with the dog better than with his master. It
        didn't even occur to me that the guest was not an aviator, because why
        would they assign him to us?
      </div>
      <div className="mb-3">
        I walked with him and his dog around the squadron, showed everything
        that was interesting to show. I took out a map, marked where we are. I
        gave him this map to study the area. The mechanic reported that my plane
        was ready, but needed for a pre-flight test before flight on assignment.
        I offered Domes to take a visitor on this test flight, to get our guest
        acquainted.
      </div>
      <div className="mb-3">
        After take off, we flew over, each with their own map. I showed the
        places on the ground and on the map in great detail, after finishing I
        turned to the airfield and was gaining altitude. You have to show the
        guest how we fly in Poland! I entertained myself with falling leaves,
        loops, spins, it was all done.
      </div>
      <div className="mb-3">
        He was genuinely delighted when we landed, his dog almost did a damage
        to airplane, concerned about the master's absence, he almost got under
        the propeller, jumped over the rudders into the cabin, and on the way.
        Dog tore the covering canvas with its claws.
      </div>
      <div className="mb-3">
        You have a difficult task for tomorrow! Domes greeted me, giving me a
        note from the Group.
      </div>
      <div className="mb-3">
        - You have to find a unit operating behind the enemy lines in the area
        of ​​the Niemen river, determine enemy location and overall situation.
        If reconnaissance is unsuccessful, the second plane will take a route
        further west of the intended one.
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-amazing-story-article-1.jpg`)
            .default
        }
        style={{ maxHeight: "500px" }}
        fluid
      />
      <div className="mb-3">
        Niekraszewicz was battered and constantly limping. He volunteered to be
        fit and ready to fly, but it was better for him to get a day or two to
        rest from flying.
      </div>
      <div className="mb-3">
        I'd better get our guest on the assignment. I tried to explain to guest
        what was our task, he was delighted that he would be flying and he
        didn't pay much attention to my explanations. He handed me his photo
        with a dedication and was busy trying a borrowed flying suit and a
        leather flying helmet, he was very excited. With my colleagues, I
        deciphered that a dedication on the photo was a thank you for the
        baptism in the air. So, he flew for the first time in his life, he was
        never an aviator. I spent the evening preparing the flight for myself. I
        tied a map on one knee, and a block with a pencil for notes on the
        other. The Belgian knew how to handle the machinegun.
      </div>
      <div className="mb-3">
        My first attempt to locate the unit was not very pleasant for us. I got
        down, watching and listening, enemy let me go very close before they
        opened fire. Fortunately, I escaped without harm. How many times can I
        do this? It's hopeless to recognize and search for a unit in this mess.
        I was over Niemen river, on the west bank there were some fresh trenches
        or fortifications, on the eastern bank were farm buildings, groves, no
        people were visible. I flew over to the west to watch some movement,
        they are waving their caps - probably this was friendly unit. I made a
        turn and circled over the buildings on the other bank. Hush, there was
        no one in sight. Suddenly we heard the intent rattle of the machine gun
        and the crisp chopping of bullets on the airplane's canvas. I dived and
        I was quickly on the other side. I knew everything.
      </div>
      <div className="mb-3">
        I'm coming back, I'm taking a course to Białystok. I was still watching
        to see if they have damaged something - everything was fine. I made a
        mark on the map, took notes , I was not in a hurry. I forgot about the
        Belgian guy, I look at the mirror on wings, I look back. He was sitting
        upright, holding a handkerchief against his bloody face, I gave him mine
        when he changed handkerchiefs, I saw the entire jaw bared, one could see
        the teeth, the blood was pulsating and the wind was splashing it in big
        drops somewhere on the controls.
      </div>
      <div className="mb-3">
        What to do? We will not make it, he will die like Antek from the loss of
        blood. To hell with the plane, save the guest, I landed next to our
        unit. I even found a nice field about 3/4 km west of unit. I didn't
        remember landing, turning off the engine, and taking the rags out of the
        cabin to my guest so he could stick them to the wound. It was quiet.
        Suddenly I felt terrible.
      </div>
      <div className="mb-3">
        Is it really friendly unit? Maybe they were waving to confuse us on the
        one hand and shoot on the other. What if it's "them"? I was leaning
        against the plane, taking out my revolver and the matches. The visitor
        was watching me closely, he was standing in his cabin.
      </div>
      <div className="mb-3">
        Riders, Siberian horses appeared from different sides, clothes
        indistinct, who knows.
      </div>
      <div className="mb-3">
        - Bolshevik! - guest shouted. He dropped all the bandages and, with a
        wound for his face, grabbed the machinegun. It took me a few seconds to
        calm him down. The riders were standing, I was standing, it was quiet.
        Probably ours, they didn't shoot. One rider detached himself, he was
        holding his cap on his raised hand, I let him ride. They were friendly
        unit – absolutely!
      </div>
      <div className="mb-3">
        - I have an injured airman, I need help. Where's the commander?
      </div>
      <div className="mb-3">
        They took Belgian, I walked slowly with the non-commissioned officer,
        asking how and what was going on.
      </div>
      <div className="mb-3">
        "We waved you not to go there, but you didn't understand."
      </div>
      <div className="mb-3">- Who's the commander?</div>
      <div className="mb-3">- Major [Bernard] Mond, you will see him soon.</div>
      <Image
        src={
          require(`../../../assets/news/badin-amazing-story-article-2.jpg`)
            .default
        }
        style={{ maxHeight: "500px" }}
        fluid
      />
      <div className="mb-3">
        I just heard once major’s Mond name, he didn't know me, but he was very
        happy. He dictated quickly what to say in the staff HQ, I was not quite
        keeping up with the notes.
      </div>
      <div className="mb-3">
        - Major, I will distort something, there will be a problem. Sir, it
        would be better if major would write down everything, and I will try to
        deliver it to HQ, I reported obediently - I added without any connection
        or sense; just like it was military. He looked at me.
      </div>
      <div className="mb-3">
        - Do you want to eat something? We don't have much, but we'll find
        something. Who is this wounded airman? - I had to tell him everything
        exactly what happened.
      </div>
      <div className="mb-3">
        "Well, he didn't get too much into the fight and he didn't get into it
        anymore," laughed the major. The paramedic brought a Belgian surrounded
        by a crowd of curious people. Big wound, but not very serious, the
        bullet has cut the cheek from mouth to ear without damaging any bone I
        have stopped bleeding as much as I could, but it still seeps the sooner
        he would be in the hospital, the better for him, he already lost a lot
        of blood.
      </div>
      <div className="mb-3">
        Starting the engine without professional help was a pain. "Scouts" from
        Mołodeczno were highly skilled technicians compared to "Johnnies" (the
        so-called affectionately privates). I taught, explained, but somehow to
        no avail, and yet I could not put a guest or any of the " Johnnies "
        into the cabin to control the engin. After all, I think the engine got
        bored of all this show and after half an hour of persistent rotating it
        surprised me and started.
      </div>
      <div className="mb-3">
        I did not want to believe my eyes, my guest moved and showed four bombs
        at the bottom of the cabin, a machine gun, he felt his face with his
        hand and pointed to the other side of the river. I do not think that he
        would have done much harm there, but he also threw the bombs and shot
        the machinegun, and I got excited strafed across the grove from my
        machinegun.
      </div>
      <div className="mb-3">
        The greeting at airfield was joyful, for it was quite unexpected. The
        time of full range has long passed, which means that they we should be
        sitting somewhere, probably somewhere behind the enemy lines.
      </div>
      <div className="mb-3">
        The first in the cabin was, of course, the dog who was mad with joy. I
        gave Domes a thick envelope on which I wrote: "Task completed, the
        report of Maj. Monda. November 24, 1920. Białystok, Lt. W.M ".
      </div>
      <div className="mb-3">
        I was off duty, I went to the field hospital and I watched for the first
        time in my life as a man was sewn together. The little doc, with a
        needle and thread, grabbed the skin near his mouth and stitched it with
        a seam, then the same around the ear. Everything now fits nicely, like
        very neatly mended pants torn on the knee. We went to a "barber" friend,
        he shaved me on both sides, and our guest on one side, he was talking,
        he was excited, but I didn't understand much. I guess it was, but I
        don't quite remember what we were talking about.
      </div>
      <div className="mb-3">
        We returned to the squadron, he could not eat, he drank broth, thickly
        interspersed with vodka. Colleagues found us talking lively, it must
        have been so, but I don't really remember what we were talking about. As
        usual, fear or the so-called emotion bypasses the human being in action,
        it comes later, most often during the silence of the night. I lay there
        late in the night, retrospecting the events of the last day, I fell
        asleep like a stone. In the morning, or rather around noon, it was quiet
        in the building, you can only hear the Belgian snoring. There must be
        something going on at the airfield, so I went out to see.
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-amazing-story-article-3.jpg`)
            .default
        }
        style={{ maxHeight: "500px" }}
        fluid
      />
      <div className="mb-3">
        My plane was disassembled, washed, cleaned and overhauled, others were
        ready for take off. Domes was nervous. HQ wanted five planes in
        readiness in the morning, and there were no orders. Naturally, the phone
        did not work. I didn't really care what I do today. I spoke to the
        mechanics, they gave me a brass piece of tube with a base, a Lewis
        machine gun barrel hanger with the remains of a bullet. Sir, this piece
        prevented you from getting a bullet in your right hand… if the bullet
        had slipped to your right - nothing would have happened, if it slipped
        to the left, the bullet would have been in my lungs. (Later I made a
        paperweight on the desk out of this part - it was lost in Warsaw in
        1939),
      </div>
      <div className="mb-3">
        - See how much blood got out of this Belgian, he was lucky to get away
        with his life – they concluded.
      </div>
      <div className="mb-3">
        They were mending a motorcycle near the hangar, something went wrong,
        but finally the engine began to croak loudly and go on. The motorbike
        was used to get Domes to the headquarters. Later, Domes told me that the
        HQ staff could not believe that it was a letter from maj. Mond. They
        looked for old letters and compared the handwriting. They simply did not
        believe their luck, and they did not trust in aviation or maybe they did
        not believe in me. They asked if it would be okay to give Belgian a
        Cross of Valor.
      </div>
      <div className="mt-5 mb-5">****</div>
      <div>
        In the end, the air traffic controller from the collection of Mr.
        Campion was donated to the Polish Historical Aircraft Foundation and is
        currently undergoing conservation work so that it can be used in our PZL
        P11c.
      </div>
      <Image
        src={
          require(`../../../assets/news/badin-historia-niezwykla-article-10.jpg`)
            .default
        }
        fluid
      />
      <div className="mb-3 mt-3">Merci beaucoup Monsieur Campion!</div>
      <div className="mb-3">
        Many thanks to Mr. Stefan Wilski for supporting our work and commitment
        to acquiring and delivering the instrument.
      </div>
      <div className="mb-3">
        We would also like to thank our friends from Kraków and Zielonka for
        their help in obtaining documents and Mr. Jan Hofman from the Polish
        Aviation Museum in Kraków for extremely colorful stories about the
        history of aviation that charmed our guest, Stefan Wilski. 
      </div>
      <div className="mb-3">
        Ps. If anyone knows any previously unknown details about the fate of
        Second Lieutenant Robert Vander Auwera, please contact the Polish
        Historical Aircraft Foundation.
      </div>
    </Container>
  );
}

export default EnBadinHistoriaNiezwykla;
