import React from "react";
import { useParams } from "react-router-dom";
import { Redirect } from "react-router";
import { Articles } from "../../data/Articles";
import { useTranslation } from "react-i18next";

import BasicArticle from "./articles/BasicArticle";
import PlKolejnaPracaMagisterkaObroniona from "./articles/PlKolejnaPracaMagisterkaObroniona";
import EnKolejnaPracaMagisterkaObroniona from "./articles/EnKolejnaPracaMagisterkaObroniona";
import AnalizaSilOporu from "./articles/AnalizaSilOporu";
import GeniuszeIMarzyciele from "./articles/GeniuszeIMarzyciele";
import PlBadinHistoriaNiezwykla from "./articles/PlBadinHistoriaNiezwykla";
import EnBadinHistoriaNiezwykla from "./articles/EnBadinHistoriaNiezwykla";

function ArticleSwitcher() {
  const { slug } = useParams();
  const { i18n } = useTranslation("common");

  switch (slug) {
    case "badin-historia-niezwykla":
      if (i18n.language === "pl") return <PlBadinHistoriaNiezwykla />;
      if (i18n.language === "en") return <EnBadinHistoriaNiezwykla />;
      break;
    case "analiza-sil-oporu-aerodynamicznego-poszczegolnych-podzespolow-samolotu-pzl-p-11c":
      return <AnalizaSilOporu />;
    case "kolejna-praca-magisterska-z-p11-stki-obroniona":
      if (i18n.language === "pl") return <PlKolejnaPracaMagisterkaObroniona />;
      if (i18n.language === "en") return <EnKolejnaPracaMagisterkaObroniona />;
      break;
    case "geniusze-i-marzyciele-zygmunt-pulawski-polskie-skrzydla":
      return <GeniuszeIMarzyciele />
    default:
      if (Articles[slug]) {
        return <BasicArticle slug={slug} />;
      }

      return <Redirect to="/" />;
  }
}

export default ArticleSwitcher;
