import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Breadcrumb, Row, Col } from "react-bootstrap";

const BlockSpan = styled.span`
  display: block;
`;

function Contact() {
  const { t } = useTranslation("common");

  return (
    <Container>
      <h1 className="mt-4 mb-3">{t("contact.header")}</h1>
      <Breadcrumb>
        <LinkContainer to="/">
          <Breadcrumb.Item>{t("menu.home")}</Breadcrumb.Item>
        </LinkContainer>
        <Breadcrumb.Item active>{t("contact.header")}</Breadcrumb.Item>
      </Breadcrumb>

      <h3 className="text-uppercase">{t("foundation.name")}</h3>
      <p>
        <BlockSpan>ul. gen. Pełczyńskiego 20/50</BlockSpan>
        <BlockSpan>{t("contact.address.postal_code")}</BlockSpan>
      </p>
      <p>
        <BlockSpan>
          KRS 000 69 20 94 XIII Wydział Gospodarczy Krajowego Rejestru Sądowego
          w Warszawie
        </BlockSpan>
        <BlockSpan>NIP 5252720788</BlockSpan>
        <BlockSpan>REGON 36 81 46 665</BlockSpan>
      </p>
      <p>
        <BlockSpan>{t("contact.bank_account.domestic")}</BlockSpan>
        <BlockSpan>78 1020 1185 0000 4602 0261 3016</BlockSpan>
      </p>
      <p>
        <BlockSpan>{t("contact.bank_account.foreign")}</BlockSpan>
        <BlockSpan>IBAN: PL78 1020 1185 0000 4602 0261 3016</BlockSpan>
        <BlockSpan>BIC: BPKOPLPW</BlockSpan>
      </p>

      <Row>
        <Col md="5">
          <p>
            <BlockSpan>
              {t("contact.foundation_president")} - Marek Masalski
            </BlockSpan>
            <span>
              TEL. <a href="tel:+48511404014">+48 511 404 014</a>
            </span>
            <BlockSpan>
              <span>
                EMAIL &nbsp;
                <a href="m.masalski@samolotyhistoryczne.org'">
                  m.masalski@samolotyhistoryczne.org
                </a>
              </span>
            </BlockSpan>
          </p>
          <p>
            <BlockSpan>
              {t("contact.foundation_vice_president")} - Wiesław Kosowicz
            </BlockSpan>
            <span>
              TEL. <a href="tel:+48609539325">+48 609 539 325</a>
            </span>
            <BlockSpan>
              <span>
                EMAIL &nbsp;
                <a href="w.kosowicz@samolotyhistoryczne.org'">
                  w.kosowicz@samolotyhistoryczne.org
                </a>
              </span>
            </BlockSpan>
          </p>
        </Col>
        <Col md="7">
          <img
            src={
              require("../../assets/samoloty_historyczne_logo_190.jpg").default
            }
            alt="Logo"
          ></img>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
